import * as React from "react";
import { render } from "react-dom";
import { useForm } from "react-hook-form";
import Headers from "./Header1";


import "./styles.css";
import HomePage from './Historial'




enum GenderEnum {
  Femenino = "Femenino",
  Masculino = "Masculino",
  Otro = "Otro"
}
enum  discapacidad{
  Fisica = "Fisica",
  Sensorial = "Sensorial",
  Intelectual = "Intelectual",
  Psiquica = "Psiquica",
  Visceral = "Visceral",
  Multiple = "Multiple",
  Ninguna = "Ninguna",
}
interface IFormInput {
  firstName: String;
  gender: GenderEnum;
  IdExpediente: string;
  nacimiento: Date;
  email: string;
  telefono: number;
  epilepsia_convulsiones: "";
  diabetes: "";
  hepatitis: "";
  embarazo: "";
  discapacidad: discapacidad;
}

type FormData = {
  firstName: string;
  lastName: string;
  gender: GenderEnum;
  IdExpediente: string;
  nacimiento: Date;
  email: string;
  telefono: number;
  direccion: string;
  epilepsia_convulsiones: "";
  diabetes: "";
  embarazo: "";
  discapacidad: string;
};

export default function App() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();
  const onSubmit = handleSubmit(({ firstName, gender, lastName, IdExpediente, nacimiento, email, telefono, direccion,epilepsia_convulsiones, diabetes, embarazo, discapacidad }) => {
    console.log(firstName, lastName, gender, IdExpediente, nacimiento, email, telefono, direccion, epilepsia_convulsiones, diabetes, embarazo, discapacidad);
  }); 

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <Headers />

      <label>Numero de expediente</label>
      <input {...register("IdExpediente")} />
      <h1>Datos personales</h1>   
      <label>Nombre</label>
      <input {...register("firstName")} />
      <label>Apellido</label>
      <input {...register("lastName")} />
      <label>Sexo</label>
      <select {...register("gender")}>
        <option value="Femenino">Femenino</option>
        <option value="Masculino">Masculino</option>
        <option value="Otro">Otro</option>
      </select>
      <label>Nacimiento</label>
      <input {...register("nacimiento")} />
      <label>Email</label>
      <input {...register("email")} />      
      <label>Telefono</label>
      <input {...register("telefono")} />      
      <label>Direccion</label>
      <input {...register("direccion")} />      
      
      <h1>Antecedentes de enfermedades</h1>      
      <div role= "group">
      <p className="p">•Ataques de epilepsia o convulsiones</p>
            <label>
              Si
              <input type="radio" name="epilepsia_convulsiones" id="epilepsia_convulsiones" value="si"></input>
            </label>
            <label>
              No
              <input type="radio" name="epilepsia_convulsiones" id="epilepsia_convulsiones" value="no"></input>
              </label>
      </div>
      <div role= "group">
      <p className="p">•Diabetes</p>
            <label>
              Si
              <input type="radio" name="diabetes" id="diabetes" value="si"></input>
            </label>
            <label>
              No
              <input type="radio" name="diabetes" id="diabetes" value="no"></input>
              </label>
      </div> 
      <div role= "group">
      <p className="p">•Hepatitis</p>
            <label>
              Si
              <input type="radio" name="hepatitis" id="hepatitis" value="si"></input>
            </label>
            <label>
              No
              <input type="radio" name="hepatitis" id="hepatitis" value="no"></input>
              </label>
      </div> 

      <div role= "group">
      <p className="p">•Embarazo</p>
            <label>
              Si
              <input type="radio" name="embarazo" id="embarazo" value="si"></input>
            </label>
            <label>
              No
              <input type="radio" name="embarazo" id="embarazo" value="no"></input>
              </label>
            <label>
            <tr>
            <td width="300px">¿Cuanto tiempo tiene de estarlo?</td><td><input id="emba" type="text" /></td>
            </tr>  
            </label>      

      </div> 
      <label><p className="p">•Tipo de discapacidad</p></label>
        <select {...register("discapacidad")}>
        <option value="ninguna">Ninguna</option>
        <option value="fisica">Discapacidad fisica</option>
        <option value="sensorial">Discapacidad sensorial</option>
        <option value="intelectual">Discapacidad intelectual</option>
        <option value="psiquica">Discapacidad psiquica</option>
        <option value="visceral">Discapacidad visceral</option>
        <option value="multiple">Discapacidad multiple</option>                            

      </select>
      <input type="submit" />
    </form>
  );
}
//export default HomePage

//const rootElement = document.getElementById("root");
//(<App />, rootElement);
